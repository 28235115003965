<template>
  <div class="content">
    <div class="main-container container"
         id="main-container">
      <div class="row">
        <div class="col-lg-12 blog__content mb-30">
          <article class="entry"
                   style="padding:0 10%;">

            <div class="single-post__entry-header entry__header">
              <div class="entry__tags">
                <span class="entry__tags-label">新闻标签:</span>
                <a href="javascript:void(0)"
                   v-for="(qitem, index) in (info.labelName || '').split(',')"
                   :key="index"
                   v-show="qitem!=''"
                   @click="tobqList(qitem)"
                   rel="tag">{{ qitem }}</a>
              </div>
              <h1 class="single-post__entry-title"
                  style="text-align:center">
                {{info.title}}</h1>

              <ul class="entry__meta"
                  style="text-align:center">
                <li class="entry__meta-date">
                  <i class="ui-date"></i>
                  {{ formatTime(info.publishtime, 'YYYY/MM/DD') }}
                </li>
                <li class="entry__meta-date">
                  来源：{{info.source}}</li>
                <li class="entry__meta-author">
                  <i class="ui-author"></i>
                </li>
                <li class="entry__meta-comments">
                  <i class="fa fa-eye"></i>
                  <a href="javascript:void(0)">{{ info.clickVolume }}</a>
                </li>
              </ul>
            </div>
            <div class="entry__img-holder"
                 v-html="info.contents">
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import format from '@/assets/unit/unit.js';
export default {
  name: 'home',
  data () {
    return {
      id: 0,
      cid: 0,
      info: ''
    };
  },
  created () {
    if (this.$route.query.id != '' && this.$route.query.id != null) {
      this.id = parseInt(this.$route.query.id);
    }
    if (this.$route.query.cid != '' && this.$route.query.cid != null) {
      this.cid = parseInt(this.$route.query.cid);
    }
    this.GetInfo();
  },
  watch: {
    $route: function () {
      if (this.$route.query.id && this.$route.query.id != this.id) {
        if (this.$route.query.id != '' && this.$route.query.id != null) {
          this.id = parseInt(this.$route.query.id);
        }
        this.GetInfo();
      }
    }
  },
  methods: {
    GetInfo () {
      let _this = this;
      _this.httphelper
        .post('/api/CrduApi/GetCrduNewInfoById', {
          column_Type: _this.cid,
          id: _this.id,
          pageIndex: 0,
          pageSize: 0
        })
        .then(res => {
          if (res.data.code == 200) {
            let arr = res.data.data.crduNewInfoViewsViews
            if (arr.length > 0) this.info = arr[0]
          }
        });
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    tobqList (str) {
      this.$router.push({
        name: 'list',
        query: {
          typeName: str,
          typeClass: 2
        }
      })
    }
  }
};
</script>
 <style scoped>
.breadcrumbs__itemnew {
    display: inline-block;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
}
.breadcrumbs__itemnew::after {
    font-family: 'ui-icons';
    display: inline-block;
    position: relative;
    top: -1px;
    font-size: 9px;
    margin: 0 6px;
}
</style>