<template>
  <div>
    <footer class="footer footer--dark">
      <div class="container">
        <div class="footer__widgets">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <div class="widget">
                <a href="#">
                  <img src="https://cgp-resource.oss-cn-beijing.aliyuncs.com/Images/News/20220214/d317cb99fd84f1ffde6bdc48d10609b7.png"
                       class="logo__img"
                       alt="">
                </a>
                <p class="mt-20">电话：010-62757857；<br>email：crdu123@126.com；<br>地址：北京市西城区广义街5号广益大厦A座907</p>
              </div>
            </div>
            <div class="col-lg-8 col-md-6">
              <div class="widget widget_nav_menu">
                <h2 class="widget-title">友情链接</h2>
                <ul>
                  <li v-for="(item, index) in linksRotation"
                      :key="index + 1">
                    <a :href="item.url"
                       target="_blank">
                      <img :src="item.resUrl"></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-12">
              <article class="post-list-small__entry clearfix">
                <div class="post-list-small__img-holder">
                  <div class="thumb-container thumb-100"
                       v-if="QRcodeRotation!=null && QRcodeRotation.length>0">
                    <img v-for="(item, index) in QRcodeRotation"
                         :key="index"
                         :src="item.resUrl"
                         alt=""
                         class="lazyload">
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
      <div class="footer__bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 order-lg-1 text-md-center">
              <span class="copyright">
                Copyright © 2018.临床合理用药网版权所有.<a href="http://www.beian.miit.gov.cn/"
                   target="_blank"
                   style="color:#878c8e;">京ICP备08100360号</a> &nbsp;
                <!--电话：010-62757857；email：crdu@crdu.cn；地址：北京市西城区广义街5号广益大厦A座907<a target="_blank" href=""></a>-->
                电话：010-62757857；&nbsp;email：crdu123@126.com；&nbsp;地址：北京市西城区广义街5号广益大厦A座907
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footer_index',
  data () {
    return {
      QRcodeRotation: [],
      linksRotation: [],
      ICPNumber: '京ICP备2021015394号-1',
      PublicNumber: '京公网安备 11010202009427号',
      PublicNumberURl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202009427'
    };
  },
  created () {
    if (window.location.host.search('chinagp.net') > -1) {
      this.ICPNumber = '冀ICP备12018774号-6';
      this.PublicNumber = '冀公网安备 13040302001295号';
      this.PublicNumberURl = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13040302001295';
    }
    this.GetRotationInfo();
  },
  methods: {
    //获取轮播数据
    GetRotationInfo () {
      let _this = this;
      let arr = [5, 6]; //分别友情链接,二维码
      for (let i = 0; i < arr.length; i++) {
        _this.httphelper
          .post('/api/CrduApi/GetRotationInfo', {
            type: arr[i],
            ruselocation: 1,
            source: 1
          })
          .then(res => {
            if (res.data.code == 200) {
              if (arr[i] == 6) {
                //二维码
                _this.QRcodeRotation = res.data.data || null;
              }
              if (arr[i] == 5) {
                //友情链接
                _this.linksRotation = res.data.data || null;
              }
            }
          });
      }
    },
    //获取栏目数据
    GetColumsInfoAllList () {
      let _this = this;
      _this.httphelper
        .post('/api/NewApi/GetColumsInfoAllList', {
          cType: 3,
          isCHorEN: 1
        })
        .then(res => {
          if (res.data.code == 200) {
            let data = res.data.data.sort(function (a, b) {
              return a.sort - b.sort;
            });
            data.forEach(item => {
              if (item.parentid == '106') {
                //首页底部信息
                _this.columntopList.push(item);
              }
            });
          }
        });
    },
    columnclk (item) {
      var contextid = 0;
      switch (item.id) {
        case 107:
          contextid = 758;
          break;
        case 108:
          contextid = 757;
          break;
        case 109:
          contextid = 464;
          break;
        case 110:
          contextid = 759;
          break;
        case 111:
          contextid = 221;
          break;
        case 112:
          contextid = 465;
          break;
      }
      const { href } = this.$router.resolve({
        path: '/home/inforDetails?',
        query: {
          id: contextid,
          column_Type: item.id
        }
      });
      window.open(href, '_blank');
    },
    getMagazine () {
      if (this.$route.path == '/magazine') {
        this.ismagazine = true;
      } else {
        this.ismagazine = false;
      }
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.name == 'magazine') {
          this.ismagazine = true;
        } else {
          this.ismagazine = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
