<template>
  <div class="content">
    <div class="main-container container post-list-container"
         id="main-container">
      <div class="row rows">
        <!-- 病例列表 -->
        <div class="col-lg-12 blog__content mb-30">
          <!-- 病例tab -->
          <div class="text-center padding-top-10">
            <ul class="tab-large">
              <li class="active">病 例</li>
              <li>处 方</li>
            </ul>
          </div>

          <!-- 分类tab -->
          <div class="text-center padding-top-10">
            <ul class="tab-small">
              <li v-for="(item, index) in popularList"
                  :key="index"
                  @click="onsearch(item.labelName)">
                <a href="#"
                   style="color:#878787;">{{ item.labelName }}</a>
              </li>
            </ul>
          </div>
          <!-- 搜索 -->
          <div class="text-center">
            <div class="search-wrap">
              <input type="text"
                     class="form-control"
                     placeholder="Search"
                     value=""
                     v-model="keyword"
                     id="keyword-ba">
              <span class="search-button"
                    id="searchBtn-ba"
                    @click="onsearch()"><i class="fa fa-search">
                  <img src="../../assets/img/search.png"
                       style="width:23px;vertical-align:none;margin-top:-20px">
                </i>
              </span>
            </div>
          </div>
          <!-- 列表 -->
          <ul class="post-list-main">
            <li class="post-list-item"
                v-for="(item, index) in yzNewList"
                :key="index">
              <div class="col-lg-6 post-list-img">
                <a href="javascript:void(0)"
                   @click="topost(item)">
                  <img :src="item.slideShow">
                </a>
              </div>
              <div class="col-lg-6 post-list-main-content">
                <h4 class="post-list-main-title">
                  <a href="javascript:void(0)"
                     @click="topost(item)">
                    {{item.title}} </a>
                </h4>
                <p class="post-list-main-description">
                  {{item.abstract}}</p>
                <div class="tagDiv">
                  <span v-for="(qitem, index) in (item.labelName || '').split(',')"
                        :key="index"
                        target="_blank">
                    <a v-if="qitem!=null && qitem!=''">{{qitem || ''}}</a>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <span class="list-page-data"
                data-p="1"
                data-count="10"></span>
          <p class="gengduo"
             data-perpage="10"
             data-index="1"
             ref="moreload1"
             @click="more()">
            <a>+ 加载更多</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import format from '@/assets/unit/unit.js';
export default {
  name: 'home',
  data () {
    return {
      cid: 17,
      keyword: '',
      labelName: '',
      yzNewList: [],
      popularList: []
    };
  },
  created () {
    //首页资讯
    this.GetCrduNewList();
    //根据栏目id获取热门标签
    this.GetPopularContentByCId();
  },
  methods: {
    //首页资讯
    GetCrduNewList () {
      let _this = this
      _this.yzNewList = [];
      _this.httphelper
        .post('/api/CrduApi/GetDbCrduNewInfoList', {
          Keyword: _this.keyword,
          Lablename: _this.labelName,
          column_Type: _this.cid,
          pageIndex: 1,
          pageSize: 10
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data.crduNewInfoViewsViews != null && res.data.data.crduNewInfoViewsViews.length > 0) {
              _this.yzNewList = res.data.data.crduNewInfoViewsViews;
            }
          }
        });
    },
    //加载更多
    more () {
      let _this = this
      _this.$refs.moreload1.dataset.index = parseInt(_this.$refs.moreload1.dataset.index) + 1
      _this.httphelper
        .post('/api/CrduApi/GetDbCrduNewInfoList', {
          Keyword: _this.keyword,
          Lablename: _this.labelName,
          column_Type: _this.cid,
          pageIndex: parseInt(_this.$refs.moreload1.dataset.index),
          pageSize: 10
        })
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data.crduNewInfoViewsViews != null && res.data.data.crduNewInfoViewsViews.length > 0) {
              res.data.data.crduNewInfoViewsViews.forEach(item => {
                _this.yzNewList.push(item);
              });
            }
          }
        });
    },
    //根据栏目id获取热门标签
    GetPopularContentByCId () {
      this.httphelper
        .get('/api/CrduApi/GetPopularContentByCId', {
          cid: this.cid,
          rowCount: 5
        })
        .then(res => {
          if (res.code == 200) {
            this.popularList = res.data
          }
        });
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    ///搜索
    onsearch (labelName) {
      let _this = this
      _this.$refs.moreload1.dataset.index = 1
      if (labelName != null && labelName != '')
        _this.labelName = labelName
      _this.GetCrduNewList();
    },
    topost (item) {
      if (item.address != null && item.address != '') {
        let _this = this;
        _this.httphelper
          .post('/api/CrduApi/GetCrduNewInfoById', {
            column_Type: item.cid,
            id: item.id,
            pageIndex: 0,
            pageSize: 0
          })
          .then(res => {
            window.open(item.address, '_blank')
          });
      } else {
        this.$router.push({
          name: 'post',
          query: {
            id: item.id,
            cid: item.cid
          }
        })
      }
    }
  }
};
</script>

